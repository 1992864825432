module.exports = {
  topBar: {
    nav1: "Home",
    nav2: "Business model",
    nav3: "Planning features",
    nav4: "Reshaping the scene",
    nav5: "APPlication",
    nav6: "Publicity materials",
    nav6: "Contact Us",
    downloadApp: "Download"
  },
  home: {
    des: "Web3 Short videos platform backed by XXXXX million users Use blockchain to empower short videos, and use short videos to link global users (Project by Atoshi with real time users:XXXXX)",
    des1: "",
    des2: "",
    btn1: "What is Atos ?",
    btn2: "ATOSHI Now",
  },
  introduce: {
    title: "项目介绍",
    des: "是全球首个区块链短视频平台，用视频链接全球用户，以创作者、观众、广告商和审核官的直接价值交换为主要原则，将短视频与区块链技术结合，实现产权上链，确权、交易，授权等商业应用，打造一个利用碎片化时间刷刷视频就可以赚钱的视频平台。",
    des1: "乐唰",
    btnText: "去了解"
  },
  businessModel: {
    title: "Ground Breaking Business Model",
    modal1: {
      title: "Users",
      des: "watch/share video/invite new user to get cryptocurrency",
      details: [
        "1)、Users are passionate: The appreciation potential of cryptocurrencies is unlimited. Bitcoin has risen by nearly 100 million times.",
        "2)、Mobile mining:Easy to use.",
        "3)、Each user holds crypo, watch more, value more, loyalty more.",
        "4)、Faster User growth: Users invite more users to download the app to get cryptocurrency.",
        "5)、Network flow is circulating, and there are more and more users.",
        "6)、The more users, the stronger the consensus and the more valuable the cryptocurrency."
      ]
    },
    modal2: {
      title: "Creators",
      des: "Each video is an NFT with blockchain copyright protection, and creators can sell the video to generate revenue; protection, and creators can sell the video to generate revenue; Afterwards, every time the video is resold, the creator will charge 10% of the video transaction price as copyright tax; No matter who owns the video, the creator will have 10% of the video's reward or advertising revenue as copyright tax.",
    },
    modal3: {
      title: "Advertisers",
      des: "They don't need to contactadvertising sales staff, as adecentralized app, they can directlybid for advertisements. Simple, efficient and direct.",
    },
    modal4: {
      title: "Judge(Reviewer)",
      des: "Users pledge their cryptocurrency, and then review the videos/advertisements and other content toget cryptocurrency as rewards.Usershave the final sayon the video content.",
    },
    modal5: {
      title: "Video buyers",
      des: "Save the trouble of shooting videos, they can directly buy videos, get thetraffic, and accelerate to becomeaninfluencer, earn advertising revenueand use other methods tomonetize the traffic.",
    },
    modal6: {
      title: "Influencer",
      des: "Save the trouble of shooting videos, buy exciting videos, become morepopular, earn advertising revenueand other waystomonetizeinfluence.",
    },
    modal7: {
      title: "Platform",
      des: "Appreciation of its Cryptocurrency by more users. Revenue from gifts, advertisements and video trading ect.. Stronger funds to develop a better user experience.",
    }
  },
  plan: {
    title: "Detok Planning features",
    modal1: {
      title: "Copyright on blockchain",
      des: "Short videos copyrights protected by blockchain. The copyright confirmation is unchangeable, traceable, permanently preserved, transferable on the chain, transparent, efficient, fair and low-cost."
    },
    modal2: {
      title: "Decentralized",
      des: "Videos are independently reviewed by users themselves, and users have the final say on the video contents. This allows users to be the masters of the platform. Advertisers doesn’t need to contact advertising sales, they can directly bids for the right to place ads themselves."
    },
    modal3: {
      title: "Each video is an NFT",
      des: "The video uploaded by the user will have an on-chain code and become an NFT. The holder rents/sells the video copyright through the blockchain, and completes the copyright sale and delivery instantly. "
    },
    modal4: {
      title: "Lower operating costs",
      des: "Users pledge cryptocurrency, pass the test, and finally become a content Judge; They decide whether the audited video should pass, to prevent bad contents from being shared. This system directly save tons of reviewing fees. The audit is usually done within minutes."
    }
  },
  scene: {
    title: "将重塑场景",
    modal1: {
      title: "重塑社交场景",
      des: "视频社交，更加直观视频打赏，用户社交更加多元 乐友圈集合朋友圈，微博等功能 建立粉丝群，万人群，实时聊天"
    },
    modal2: {
      title: "重塑购物场景",
      des: "视频（直播）展示，更生动 边看边买，随心购物更自在"
    },
    modal3: {
      title: "重塑营销场景",
      des: "视频营销，生动直观 视频广告，形式多样化"
    },
    modal4: {
      title: "重塑本地生活场景",
      des: "视频动态展示，提高决策效率"
    },
    modal5: {
      title: "重塑游戏场景",
      des: "随玩随走，更低的用户体验成本"
    }
  },
  repurchase: {
    title: "ATOS planned Application scenarios",
    modal1: {
      des: "Purchase gifts, Reward videos requires ATOS. Platform and users share the ATOS income."
    },
    modal2: {
      des: "Users needs to pledge ATOS in order to become a judge in order to review videos."
    },
    modal3: {
      des: "Purchasing and renting videos requires ATOS. Video renter and seller share ATOS income with platform.",
    },
    modal4: {
      des: "Advertisers need to use ATOS to place advertisements. Video Creators and platform share the ATOS income.",
    },
    modal5: {
      des: "Opening a shop the mall requires pledging of ATOS. Mall management fees can be paid with ATOS.",
    },
    modal6: {
      des: "Service fee of VIP users are paid in ATOS and other value-added services such as removing ads.",
    }
  },
  publicityMaterials: {
    title: "Publicity materials",
    video: {
      title: "Video",
      ceo: "CEO廖望简介",
      total: " video"
    },
    news: {
      title: "News"
    },
    picture: {
      title: "Album",
      total: " pictures",
      totalDetails: " pictures",
      origin: "Full Image"
    },
    audio: {
      title: "音频资料",
      totla: "共X个音频"
    },
    more: "More",
    fold: "Collapse"
  },
  download: {
    des: "乐唰好玩又赚钱",
    downloadIos: "IOS 下载",
    inDevelopment: "IOS开发中",
    downloadAndroid: "安卓手机下载"
  },
  contact: {
    title: "联系我们",
    official: "Official Links:",
    ceo: "CEO Liao Wang:",
    by: "A project by Atoshi",
    service: "技术客服：",
    serviceDes: "仅限技术问题"
  },
  toast: "IOS开发中，敬请期待"
}